@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@400;600&display=swap");

:root {
  --dark: #1d1d1d;
  --light: #fff;
  --green: #28a92b;
}

body {
  background-color: var(--dark);
  color: var(--light);
  font-family: "Signika Negative", sans-serif;
  width: 100%;
  padding: 0;
  height: 100vh;
}

.box {
  width: 20vw;
  height: auto;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #000;
  font-weight: 600;
  color: var(--light);
}

.App {
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

@font-face{ 
	font-family: 'Bulevar-Poster';
	src: url('fonts/bulevar-poster-webfont.eot');
	src: url('fonts/bulevar-poster-webfont.eot?#iefix') format('embedded-opentype'),
	     url('fonts/bulevar-poster-webfont.woff') format('woff'),
	     url('fonts/bulevar-poster-webfont.ttf') format('truetype');
}

@font-face{ 
	font-family: 'Bulevar-Regular';
	src: url('fonts/bulevar-regular-webfont.eot');
	src: url('fonts/bulevar-regular-webfont.eot?#iefix') format('embedded-opentype'),
	     url('fonts/bulevar-regular-webfont.woff') format('woff'),
	     url('fonts/bulevar-regular-webfont.ttf') format('truetype');
}



.section
{
  padding: 5vw 0 5vw 5vw;
}

.name-callout{
  font-family: 'Bulevar-Poster';
  font-size: 3vw; 
  letter-spacing: .2rem;
}

.white-bg
{
  background-color: #fff;
}

.white-bg::after
{
  content: "";
  clear: both;
  display: table;
}

.hdr-row-1{
  font-family: 'Bulevar-Regular';
  font-size: 2vw; 
  margin-top: 0px;
  padding-top: 0px;
  height: 1vw;
}

.hdr-row-3{
  margin-left: 10vw;
}

.callout{
  font-family: 'Bulevar-Regular';
  font-size: 20vw; 
  height: 15vw;
}

.tagline{
  font-family: 'Bulevar-Regular';
  font-size: 3vw; 
}

.row-4{
  display: grid;
  grid-template-columns: 30vw 35vw; /*columns widths*/
  height: 19vw;
}

.globe-container{
  height: 19vw;
  padding-top: 16%;
  margin-left: 23vw;
}

.globe-map{
  width: 90%; 
  height: auto;
  display: flex;
}

.content-intro
{
  color: #000;
  width: 30%;
  padding-left: 2vw;
  float: left;
}

.romke01
{
  float: left;
}

.romke01 img
{
  width: 30vw;
}

.clear
{
  clear: both;
}

.brand-logos
{
  columns: 3;
  width: 60%;
}

.logo-lockup
{
  width: 50%;
  height: 20%;
  padding-top: 1vw;
}

h1{
  font-family: 'Bulevar-Poster';
  font-size: 5.9vw;
}

h2 {
  font-size: 5.0vw;
  font-family: 'Bulevar-Poster';
}
h3 {
  font-size: 4.0vh;
  font-family: 'Bulevar-Poster';
}
p {
  font-size: 2vmin;
}

.quote
{
  font-size: 3vmin;
}

/* unvisited link */
a:link {
  color: hotpink;
}

/* visited link */
a:visited {
  color: green;
}

/* mouse over link */
a:hover {
  color: hotpink;
}

/* selected link */
.contact-link:link, .contact-link:visited, .contact-link:active {
  color: white;
  text-decoration: none;
}